@import "@/assets/scss/style.scss";

.c-client-view {
    padding: 24px 24px;
    background-color: white;

    &__button-group {
        margin-bottom: 10px;
    }

    &__number-of-results {
        color: #8c8c8c;
    }

    &__overlay {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        height: 100%;
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        backdrop-filter: blur(2px);

        .prograss-bar__wrapper {
            width: 70%;
            margin-bottom: 10rem;
        }
    }
}

::v-deep .ant-progress-bg {
    height: 20px !important;
}
